import React from 'react';

export const NotConnectedButton = () => {
    return (
        <>
            <div>
                Need connected
            </div>
        </>
    );
};
