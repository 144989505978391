import React, { useEffect, useState } from "react";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";

import useAutoConnect from "./hooks/useAutoConnect";

import { Container } from "@mui/material";
import { NavBar } from "./common/navigation/Navbar";
import MainPage from "./components/MainPage";
import { Paper } from "@mui/material";
import Page404 from "./components/Page404";

const App = () => {
    useAutoConnect();

    return (
        <Router>
            <Container maxWidth="lg">
                <NavBar />
                <Paper sx={{ padding: "2rem" }}>
                    <Switch>
                        <Route path={`/`} exact>
                            <Page404 />
                        </Route>
                        <Route path={`/unlock/:linkId`}>
                            <MainPage />
                        </Route>
                        <Route path={"*"}>
                            <Redirect to="/" />
                        </Route>
                    </Switch>
                </Paper>
            </Container>
        </Router>
    );
};
export default App;
