import { useEffect } from "react";
import { useWeb3React } from "@web3-react/core";

import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

const useAutoConnect = () => {
	const { activate } = useWeb3React();
	const connectorKey = "connector";

	useEffect(() => {
		const Injected = new InjectedConnector({
			supportedChainIds: [
				1, // Mainet
				3, // Ropsten
				4, // Rinkeby
				5, // Goerli
				42, // Kovan
				1337, // dev
				11155111, // sepolia
			],
		});

		const WalletConnect = new WalletConnectConnector({
			rpc: {
				1: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
				3: `https://ropsten.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
				4: `https://rinkeby.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
				5: `https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
				42: `https://kovan.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
				11155111: `https://sepolia.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
			},
			qrcode: true,
			bridge: "https://bridge.walletconnect.org",
			pollingInterval: 12000,
		});
		const WALLETS = [
			{
				name: "Metamask",
				connector: Injected,
			},
			{
				name: "Wallet Connect",
				connector: WalletConnect,
			},
		];
		const walletName = localStorage.getItem(connectorKey);

		if (walletName !== null) {
			const wallet = WALLETS.find((wallet) => wallet.name === walletName);

			if (wallet) {
				activate(wallet.connector);
			}
		}
	}, [activate]);
};

export default useAutoConnect;
