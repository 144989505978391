import React, {useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { formatEther } from '@ethersproject/units';

export const Balance = () => {
    const { account, library, chainId } = useWeb3React();
    const [ balance, setBalance ] = useState("0");

    useEffect(() => {
        if (!!account && !!library) {
            let stale = false;
            library
                .getBalance(account)
                .then((balance) => {
                    if (!stale) {
                        setBalance(balance);
                    }
                })
                .catch(() => {
                    if (!stale) {
                        setBalance(0);
                    }
                });
        }
    }, [ account, library, chainId ]);

    const minimizeBalance = (bal) => {
        const showBalance = formatEther(bal);
        if (showBalance.length > 10) {
            return `${showBalance.slice(0, 9)}...`;
        }

        return showBalance;
    };

    return (
        <>
            <span>
                {balance === null
                    ? 'Error'
                    : balance
                        ? `${minimizeBalance(balance)} ETR`
                        : ''}
            </span>
        </>
    );
};
