import React from 'react';
import ReactDOM from 'react-dom';
import { Web3ReactProvider } from '@web3-react/core';
import { ethers } from 'ethers';

import App from './App';

const getLibrary = (connector) => {
  const library = new ethers.providers.Web3Provider(connector);
  library.pollingInterval = 12000;
  return library;
}

ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <React.StrictMode>
        <App />
    </React.StrictMode>
  </Web3ReactProvider>,
  document.getElementById('root')
);
