import React from "react";

import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { useConnect } from "../hooks/useConnect";

export const WithoutAuth = () => {
	const Injected = new InjectedConnector({
		supportedChainIds: [1, 4, 42, 1337, 11155111],
	});

	const WalletConnect = new WalletConnectConnector({
		qrcode: true,
		pollingInterval: 12000,
	});
	const WALLETS = [
		{
			name: "Metamask",
			connector: Injected,
		},
		{
			name: "Wallet Connect",
			connector: WalletConnect,
		},
	];

	const { connect } = useConnect();

	const walletConnect = async (key) => {
		if (key === "Metamask") {
			connect(WALLETS[0]);
		} else if (key === "Wallet Connect") {
			connect(WALLETS[1]);
		}
	};
	return (
		<Box
			maxWidth="md"
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexWrap: "wrap",
				minHeight: "50vh",
				margin: "0 auto",
			}}
		>
			<Typography
				component="h1"
				sx={{ width: "100%", textAlign: "center" }}
				variant="h2"
			>
				Sorry, no access.
			</Typography>
			<Typography
				component="p"
				sx={{ width: "100%", textAlign: "center" }}
				variant="h4"
			>
				To continue, you need to connect your wallet
			</Typography>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexWrap: "wrap",
				}}
			>
				<Box
					sx={{
						width: "100%",
						height: "1px",
						backgroundColor: "#CFCFCF",
						margin: "0.5rem 0",
						display: "flex",
					}}
					component="span"
				></Box>
				<Typography
					sx={{
						fontSize: "18px",
						lineHeight: "34px",
						fontFamily: '"DMSans-Regular"',
						width: "100%",
						textAlign: "center",
					}}
					component="p"
				>
					You can connect your wallet with one of the following wallet
					providers.
				</Typography>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-around",
						width: "100%",
						flexWrap: "wrap",
						marginTop: "1rem",
						marginRight: "1rem",
						marginLeft: "1rem",
					}}
				>
					<Box
						sx={{
							"&:hover": {
								color: "#FFFFFF",
								backgroundColor: "#366CE3",
							},
							minWidth: "33.3%",
							backgroundColor: "#F2F2F2",
							borderRadius: "24px",
							padding: "28px 24px 20px 24px",
							transition: "0.3s",
						}}
						onClick={() => walletConnect("Metamask")}
					>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								width: "60px",
								height: "60px",
								borderRadius: "12px",
								backgroundColor: "#FFFFFF",
								boxShadow: "0px 20px 50px rgba(0, 0, 0, 0.1)",
								margin: "0 0 12px 0",
							}}
						>
							<Box
								sx={{ verticalAlign: "middle" }}
								component="img"
								src="../assets/img/wallet-icon-1.png"
								alt="icon MetaMask"
							/>
						</Box>
						<Box
							sx={{
								fontSize: "20px",
								lineHeight: "36px",
								fontFamily: '"DMSans-Bold"',
							}}
							component="h5"
						>
							MetaMask
						</Box>
					</Box>

					<Box
						sx={{
							"&:hover": {
								color: "#FFFFFF",
								backgroundColor: "#366CE3",
							},
							minWidth: "33.3%",
							backgroundColor: "#F2F2F2",
							borderRadius: "24px",
							padding: "28px 24px 20px 24px",
							transition: "0.3s",
						}}
						onClick={() => walletConnect("Wallet Connect")}
					>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								width: "60px",
								height: "60px",
								borderRadius: "12px",
								backgroundColor: "#FFFFFF",
								boxShadow: "0px 20px 50px rgba(0, 0, 0, 0.1)",
								margin: "0 0 12px 0",
							}}
						>
							<Box
								sx={{ verticalAlign: "middle" }}
								component="img"
								src="../assets/img/wallet-icon-2.png"
								alt="icon WalletConnect"
							/>
						</Box>
						<Box
							sx={{
								fontSize: "20px",
								lineHeight: "36px",
								fontFamily: '"DMSans-Bold"',
							}}
							component="h5"
						>
							WalletConnect
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
