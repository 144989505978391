import React, { useEffect, useState } from "react";

// import { useWeb3React } from '@web3-react/core';
import { useConnect } from "../../hooks/useConnect";
import { Box, Button, Typography } from "@mui/material";
import { Balance } from "../Balance";

export const ConnectedButton = ({account}) => {

    const { disconnect } = useConnect();
    const [user, setUser] = useState("0");
    const [showModal, setShowModal] = useState(false);

    const modalToggle = () => {
        setShowModal(!showModal);
    };

    const shotAccount = () => {
        if (user) {
            console.log(
                "🚀 ~ file: ConnectedButton.js ~ line 24 ~ shotAccount ~ user",
                user
            );
            return user.slice(0, 6) + "..." + user.slice(-6);
        }
    };
    
    useEffect(() => {
        setUser(account);
    }, [account]);

    return (
        <Box onClick={modalToggle}>
            <Box sx={{ position: "relative" }}>
                <Box
                    sx={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        height: "52px",
                        borderRadius: "12px",
                        border: "1px solid #CFCFCF",
                        transition: "0.3s",
                        cursor: "pointer",
                    }}
                    component="span"
                >
                    <Box
                        sx={{ width: "auto", height: "100%" }}
                        component="img"
                        alt="guest"
                        src="../assets/img/user-Image17.png"
                    />
                    <Typography
                        sx={{ margin: "0 16px", textAlign: "center" }}
                        component="p"
                    >
                        Guest
                    </Typography>
                    <Box sx={{ margin: "0 16px" }} component="p">
                        <Balance />
                    </Box>
                </Box>
                {showModal ? (
                    <Box
                        sx={{
                            position: "absolute",
                            zIndex: "999",
                            top: "5rem",
                            right: "0",
                            backgroundColor: "#FFFFFF",
                            width: "275px",
                            borderRadius: "24px",
                            padding: "20px 28px",
                            transition: "0.3s",
                            boxShadow: "0px 20px 50px rgba(0, 0, 0, 0.1)",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "20px",
                                lineHeight: "36px",
                                fontFamily: "DMSans-Bold",
                                color: "#000000",
                            }}
                            component="h5"
                        >
                            Guest
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    lineHeight: "32px",
                                    fontFamily: "DMSans-Regular",
                                    color: "#000000",
                                }}
                                component="p"
                            >
                                {shotAccount()}
                            </Typography>
                            <svg
                                className="copy-icon"
                                fill="none"
                                height="18"
                                width="18"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12.636 12.636H15a2 2 0 002-2V3a2 2 0 00-2-2H7.364a2 2 0 00-2 2v2.364"
                                    stroke="#CFCFCF"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                />
                                <path
                                    d="M10.636 5.364H3a2 2 0 00-2 2V15a2 2 0 002 2h7.636a2 2 0 002-2V7.364a2 2 0 00-2-2z"
                                    stroke="#CFCFCF"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                />
                            </svg>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: "#F2F2F2",
                                borderRadius: "16px",
                                padding: "12px 20px 12px 16px",
                                margin: "20px 0",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "52px",
                                    height: "52px",
                                    borderRadius: "100%",
                                    backgroundColor: "#FFFFFF",
                                    margin: "0 12px 0 0",
                                }}
                            >
                                <Box
                                    sx={{
                                        verticalAlign: "middle",
                                    }}
                                    component="img"
                                    alt="img"
                                    src="../assets/img/ethereum.png"
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "16px",
                                        lineHeight: "32px",
                                        fontFamily: '"DMSans-Regular"',
                                        color: "#999999",
                                    }}
                                    component="p"
                                >
                                    Current Balance
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "20px",
                                        lineHeight: "36px",
                                        fontFamily: '"DMSans-Bold"',
                                        color: "#000000",
                                    }}
                                    component="h5"
                                >
                                    <Balance />
                                </Typography>
                            </Box>
                        </Box>
                        <Button
                            className="profile body-sb"
                            onClick={() => disconnect()}
                        >
                            Disconnect
                        </Button>
                    </Box>
                ) : null}
            </Box>
        </Box>
    );
};
