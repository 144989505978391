import React from "react";

import { useWeb3React } from "@web3-react/core";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import { ConnectedButton } from "./ConnectedButton";
import { NotConnectedButton } from "./NotConnectedButton";

export const NavBar = () => {
    const { account } = useWeb3React();

    return (
            <AppBar position="static">
                <Toolbar
                    sx={{ display: "flex", justifyContent: "space-between" }}
                >
                    <Box sx={{ mr: 2 }}>
                        <img
                            style={{ width: "100%", height: "auto" }}
                            src="../../assets/img/brand-logo-light.png"
                            alt="logo"
                        />
                    </Box>
                    <Box
                        sx={{ display: "flex", alignItems: "center", padding: "32px 0" }}
                    >
                        {account ? (
                            <ConnectedButton account={account} />
                        ) : (
                            <NotConnectedButton />
                        )}
                    </Box>
                </Toolbar>
            </AppBar>
    );
};
