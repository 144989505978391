import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import {
	InjectedConnector,
	NoEthereumProviderError,
	UserRejectedRequestError,
} from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

export const useConnect = () => {
	const POLLING_INTERVAL = 12000;
	const Injected = new InjectedConnector({
		supportedChainIds: [
			1, // Mainet
			3, // Ropsten
			4, // Rinkeby
			5, // Goerli
			42, // Kovan
			1337, // dev
			11155111, // sepolia
		],
	});

	const WalletConnect = new WalletConnectConnector({
		rpc: {
			1: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
			3: `https://ropsten.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
			4: `https://rinkeby.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
			5: `https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
			42: `https://kovan.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
			11155111: `https://sepolia.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
		},
		qrcode: true,
		bridge: "https://bridge.walletconnect.org",
		pollingInterval: POLLING_INTERVAL,
	});
	const WALLETS = [
		{
			name: "Metamask",
			connector: Injected,
		},
		{
			name: "Wallet Connect",
			connector: WalletConnect,
		},
	];
	const { activate, deactivate } = useWeb3React();

	const connect = useCallback(
		async (wallet) => {
			localStorage.setItem("connector", wallet.name);

			await activate(wallet.connector, (error) => {
				if (
					error instanceof NoEthereumProviderError ||
					error instanceof UserRejectedRequestError
				) {
					localStorage.removeItem("connector");
				}
			});
		},
		[activate]
	);

	const disconnect = useCallback(async () => {
		localStorage.removeItem("connector");

		await deactivate();

		if (window.localStorage.getItem("walletconnect")) {
			const walletConnect = WALLETS.find(
				(wallet) => wallet.name === "Wallet Connect"
			);

			if (walletConnect) {
				const { connector } = walletConnect;

				connector.close();
				connector.walletConnectProvider = null;
			}
		}
	}, [deactivate]);

	return { connect, disconnect };
};
